import './index.less';

const InfoBlock = (props) => {
  const { title, detail, imgSource, imageH } = props;
  return <div className='InfoBlock1'>
    {
      title ? <div className='title'>{title}</div> : null
    }
    <div className='detailCon'>
      {
        detail.map((res, index) => {
          return <div className='detail' key={index}>{res}</div>
        })
      }
    </div>
    {
      imgSource ? <div className='imgSourceCon'>
        {
          imgSource.map((res, index) => {
            return <img key={index} src={res} className='imgSource' style={imageH ? { height: `${imageH}rem` } : null} />
          })
        }
      </div> : null
    }


  </div>
}

export default InfoBlock;