import React from "react";
import './index.less';
import Font1 from '../../assets/img/entertainment/font1.webp';
import Font2 from '../../assets/img/entertainment/font2.webp';
import Font4 from '../../assets/img/game/font4.webp';
import Header from "../../components/header";
import Line from "../../components/line";
import Img1 from '../../assets/img/game/1.webp';
import InfoBlock from "./components/infoBlock";
import ImageList from "./components/imgList";
import Img2_1 from '../../assets/img/game/2-1.webp';
import Img2_2 from '../../assets/img/game/2-2.webp';
import Img2_3 from '../../assets/img/game/2-3.webp';
import Img3_1 from '../../assets/img/game/3-1.webp';
import Img3_2 from '../../assets/img/game/3-2.webp';
import Img3_3 from '../../assets/img/game/3-3.webp';
import Img4_1 from '../../assets/img/game/4-1.webp';
import Img4_2 from '../../assets/img/game/4-2.webp';
import Img5 from '../../assets/img/game/5.webp';
import Img6 from '../../assets/img/game/6.webp';
import Img7 from '../../assets/img/game/7.webp';
import Img8 from '../../assets/img/game/8.webp';
import Img9 from '../../assets/img/game/9.webp';
import Img10 from '../../assets/img/game/10.webp';
import Img11_1 from '../../assets/img/game/11-1.webp';
import Img11_2 from '../../assets/img/game/11-2.webp';
import Img11_3 from '../../assets/img/game/11-3.webp';
import Img11_4 from '../../assets/img/game/11-4.webp';
import Img12_1 from '../../assets/img/game/12-1.webp';
import Img12_2 from '../../assets/img/game/12-2.webp';
import Img12_3 from '../../assets/img/game/12-3.webp';
import Img12_4 from '../../assets/img/game/12-4.webp';
import Img13_1 from '../../assets/img/game/13-1.webp';
import Img13_2 from '../../assets/img/game/13-2.webp';
import Img13_3 from '../../assets/img/game/13-3.webp';
import Img13_4 from '../../assets/img/game/13-4.webp';
import Info1 from '../../assets/img/game/infoImg/1.webp';
import Info2 from '../../assets/img/game/infoImg/2.webp';
import Info3 from '../../assets/img/game/infoImg/3.webp';
import Info4 from '../../assets/img/game/infoImg/4.webp';
import Info5 from '../../assets/img/game/infoImg/5.webp';
import BottomView from '../../components/bottomView';
import ToTop from "../../components/toTop";

const Game = () => {
  return <div className="game">
    <Header index='wisdom' bc='#EDEDED' />
    <div className="Enter_the">THE</div>
    <img alt="" src={Font1} style={{ width: '1.57rem', height: '.43rem' }} />
    <div className="Incarentertainment">Game project</div>
    <div className="cnyl">游戏项目</div>
    <Line bc='#000' lineHeight={2.11} mt={.25} mb={.29} />
    <div className="qhz">
      <span className="hoverStyle">车机游戏.</span>
      <span className="hoverStyle">PC游戏.</span>
      <span className="hoverStyle">手机游戏.</span>
      <span className="hoverStyle">其他.</span>
    </div>
    <div className="weHave">我们拥有专业的知识、高效的流程和优秀的方案</div>
    <div className="mainCon">
      <img alt="" src={Img1} className='imgbig' />
      <img alt="" src={Font2} style={{ width: '3.92rem' }} />
      <Line lineHeight={1.32} mt={.7} mb={.69} />
      <img alt="" src={Info1} style={{ height: '0.82rem',marginBottom:'.6rem' }} />
      {/* <InfoBlock detail={['“游戏事业”六年来合作于国内外数百家企业', '开发了众多知名产品，如《QQ超市》《刀剑神域》《不良人》《全民主公》《捕鱼达人》等;目前在与白鹭、腾讯西南事业部等展开合作。']} /> */}
      <ImageList list={[Img2_1, Img2_2, Img2_3]} />
      <Line lineHeight={1.32} mt={.55} mb={.43} />
      <img alt="" src={Info2} style={{ height: '2.85rem',marginBottom:'.3rem' }} />
      {/* <InfoBlock title='GAME' detail={['公司业务范围包含VR自研技术开发，软件定制开发以及协助开发，客户端游戏、移动端应用以及VR虚拟现实项目。', '2013年主创人员开始接触VR行业，先后为四川旅游局及多家科研所提供VR技术支持及项目制作', '目前在动态捕捉系统与VR显示设备整合方面有一定成果；同时也为VR设备轻量化，模块化，商业化提供各种解决方案。']} /> */}
      <ImageList list={[Img3_1, Img3_2, Img3_3]} />
      <Line lineHeight={1.32} mt={.55} mb={.43} />
      <img alt="" src={Info3} style={{ height: '1.57rem',marginBottom:'.3rem' }} />
      {/* <InfoBlock title='VR落笔洞传奇' detail={['以海南三亚旅游景区的落笔洞传说为基础，还原地形风貌，添加了部分神话元素让景区更有传奇色彩。']} /> */}
      <ImageList list={[Img4_1, Img4_2]} />
      <Line lineHeight={1.32} mt={.55} mb={.43} />
      <img alt="" src={Info4} style={{ height: '1.57rem',marginBottom:'.3rem' }} />
      {/* <InfoBlock title='VR自然博物馆' detail={['利用VR技术再现古生物形象及生活环境，生动传达知识传承']} /> */}
      <ImageList list={[Img5]} />
      <Line lineHeight={1.32} mt={.55} mb={.43} />
      <img alt="" src={Info5} style={{ height: '2.31rem',marginBottom:'.3rem' }} />
      {/* <InfoBlock title='其他游戏项目' detail={['公司创立之初便以“打造行业顶尖游戏产品”为宗旨', '核心成员均是从业经历10年以上的资深游戏开发人员。除传统游戏及应用开发之外', '公司目前与VR行业多家知名企业积极展开合作，打造完全独立IP的虚拟现实游戏产品。']} /> */}
      <Line lineHeight={1.32} mt={.55} mb={.43} />
      <img alt="" src={Img6} style={{ height: '8.74rem' }} />
      <Line lineHeight={1.32} mt={.55} mb={.43} />
      <img alt="" src={Img7} style={{ height: '9.35rem' }} />
      <Line lineHeight={1.32} mt={.55} mb={.43} />
      <img alt="" src={Img8} style={{ height: '6.79rem' }} />
      <Line lineHeight={1.32} mt={.55} mb={.43} />
      <img alt="" src={Img9} style={{ height: '6.54rem' }} />
      <Line lineHeight={1.32} mt={.55} mb={.43} />
      <img alt="" src={Img10} style={{ height: '6.54rem' }} />
      <Line lineHeight={1.32} mt={.55} mb={.43} />
      <ImageList list={[Img11_1, Img11_2, Img11_3, Img11_4]} />
      <Line lineHeight={1.32} mt={.55} mb={.43} />
      <ImageList list={[Img12_1, Img12_2, Img12_3, Img12_4]} />
      <Line lineHeight={1.32} mt={.55} mb={.43} />
      <ImageList list={[Img13_1, Img13_2, Img13_3, Img13_4]} />
      <img alt="" src={Font4} style={{ width: '6.24rem', height: '3.34rem',marginTop:'.2rem',marginBottom:'.2rem' }} />
    </div>
    <BottomView loca='Game' />
    <ToTop/>
  </div>

}




export default Game;