import './index.less';

const ImageList = (props)=>{
  const {list} = props
  return <div className="imageList">
    {
      list.map((res,index)=>{
        return <img key={index} src={res} className='img654'/>
      })
    }
</div>
}
export default ImageList;