import React from "react";
import './index.less';
import Header from "../../components/header";
import Line from '../../components/line';
import BottomView from "../../components/bottomView";
import YearBlock from "./components/yearBlock";
import I13 from '../../assets/img/aboutus/13.webp';
import I14 from '../../assets/img/aboutus/14.webp';
import I15 from '../../assets/img/aboutus/15.webp';
import I16 from '../../assets/img/aboutus/16.webp';
import I17 from '../../assets/img/aboutus/17.webp';
import I18 from '../../assets/img/aboutus/18.webp';
import I19 from '../../assets/img/aboutus/19.webp';
import I20 from '../../assets/img/aboutus/20.webp';
import I21 from '../../assets/img/aboutus/21.webp';
import I22 from '../../assets/img/aboutus/22.webp';
import I23 from '../../assets/img/aboutus/23.webp';
import ToTop from "../../components/toTop";
import Font3 from '../../assets/img/font3.webp'
import AboutUsB from '../../assets/img/aboutus/aboutUsB.webp'



const AboutUs = () => {
  return <div className="aboutUs">
    <Header index='aboutUs' />
    <div className="CONTACT">About</div>
    <div className="FROMBEIJING">ESTABLISHED</div>
    <div className="totheWORLD">in 2013</div>
    <div className="CONTACT">us</div>
    <div className="wnCon">
      <a style={{color:'#000'}} href="https://baike.baidu.com/item/北京蜂鸟互动科技有限公司/19890656?fr=aladdin" target='_blank'>
        <div className="guanyuwm">关于我们</div>
      </a>
    </div>

    <Line lineHeight={2.11} mt={.25} mb={.61} />
    <img alt="" className='font3' src={Font3} />
    {/* <div className="text1">“北京蜂鸟互动”是一家具有十五年游戏开发基因的内容定制公司。</div>
    <div className="text1" style={{marginBottom:'0.33rem'}}>公司成立于2013年，目前致力于“车内娱乐内容事业”,“游戏事业”两个业务板块。</div>
    <div className="text2">“驾舱娱乐内容事业”近年来一直深耕于娱乐技术与汽车驾舱结合的拓展和应用</div>
    <div className="text2">如“广汽”《ADiGO WORLD》车机系统游戏</div>
    <div className="text2">“奔 驰”EQ400/C200/车内AR解谜游戏</div>
    <div className="text2">“佛吉亚”车机系统游戏</div>
    <div className="text2">“奥迪”E-TRON概念车/VR无人驾驶功能测验</div>
    <div className="text2">“宝马”VR个性化购车</div>
    <div className="text2">“一汽丰田” 奕泽/VR越野驾乘体验</div>
    <div className="text2">“一汽红旗”智能小巴/VR驾乘体验</div>
    <div className="text2">“哪吒汽车”纯电520U/400U/AR线上虚拟发布会等 </div>
    <div className="text2">“游戏事业”六年来合作于国内外数百家企业，开发了众多知名产品，如《QQ超市》《刀剑神域》《不良</div>
    <div className="text2">人》《全民主公》《捕鱼达人》等，目前与白鹭、腾讯西南事业部等展开合作。</div> */}
    <div className="blackBlock">
      <div className="AHISTORY">A HISTORY</div>
      <div className="ofSUCCESS">of SUCCESS</div>
      <YearBlock imgSource={I13} />
      <YearBlock imgSource={I14} />
      <YearBlock imgSource={I15} />
      <YearBlock imgSource={I16} />
      <YearBlock imgSource={I17} />
      <YearBlock imgSource={I18} />
      <YearBlock imgSource={I19} />
      <YearBlock imgSource={I20} />
      <YearBlock imgSource={I21} />
      <YearBlock imgSource={I22} />
      <YearBlock imgSource={I23} />
      <img alt="" src={AboutUsB} style={{ width: '100%' }} />
    </div>
    <BottomView loca='About' />
    <ToTop red />
  </div>
}



export default AboutUs;