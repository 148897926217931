import L1_1 from './l1-1.webp';
import L1_2 from './l1-2.webp';
import L1_3 from './l1-3.webp';
import L1_4 from './l1-4.webp';
import L1_5 from './l1-5.webp';
import L2_1 from './l2-1.webp';
import L2_2 from './l2-2.webp';
import L2_3 from './l2-3.webp';
import L2_4 from './l2-4.webp';
import L2_5 from './l2-5.webp';
import L3_1 from './l3-1.webp';
import L3_2 from './l3-2.webp';
import L3_3 from './l3-3.webp';
import L3_4 from './l3-4.webp';
import L3_5 from './l3-5.webp';

import L4_1 from './l4-1.webp';
import L4_2 from './l4-2.webp';
import L4_3 from './l4-3.webp';
import L4_4 from './l4-4.webp';
import L4_5 from './l4-5.webp';

import L5_1 from './l5-1.webp';
import L5_2 from './l5-2.webp';
import L5_3 from './l5-3.webp';
import L5_4 from './l5-4.webp';
import L5_5 from './l5-5.webp';

import L6_1 from './l6-1.webp';
import L6_2 from './l6-2.webp';
import L6_3 from './l6-3.webp';
import L6_4 from './l6-4.webp';
import L6_5 from './l6-5.webp';

import L7_1 from './l7-1.webp';
import L7_2 from './l7-2.webp';
import L7_3 from './l7-3.webp';
import L7_4 from './l7-4.webp';
import L7_5 from './l7-5.webp';

import L8_1 from './l8-1.webp';
import L8_2 from './l8-2.webp';
import L8_3 from './l8-3.webp';
import L8_4 from './l8-4.webp';
import L8_5 from './l8-5.webp';

import L9_1 from './l9-1.webp';
import L9_2 from './l9-2.webp';
import L9_3 from './l9-3.webp';
import L9_4 from './l9-4.webp';
import L9_5 from './l9-5.webp';

import L10_1 from './l10-1.webp';
import L10_2 from './l10-2.webp';
import L10_3 from './l10-3.webp';
import L10_4 from './l10-4.webp';
import L10_5 from './l10-5.webp';
export default {
  line1:[L1_1,L1_2,L1_3,L1_4,L1_5],
  line2:[L2_1,L2_2,L2_3,L2_4,L2_5],
  line3:[L3_1,L3_2,L3_3,L3_4,L3_5],
  line4:[L4_1,L4_2,L4_3,L4_4,L4_5],
  line5:[L5_1,L5_2,L5_3,L5_4,L5_5],
  line6:[L6_1,L6_2,L6_3,L6_4,L6_5],
  line7:[L7_1,L7_2,L7_3,L7_4,L7_5],
  line8:[L8_1,L8_2,L8_3,L8_4,L8_5],
  line9:[L9_1,L9_2,L9_3,L9_4,L9_5],
  line10:[L10_1,L10_2,L10_3,L10_4,L10_5]
}