import React from "react";
import './index.less';
import '../../assets/fonts.css';
import Header from "../../components/header";
import Whereweare from '../../assets/img/contact/whereweare.webp';
import Line from '../../components/line';
import BottomView from "../../components/bottomView";
import ToTop from "../../components/toTop";
import WeChat from '../../assets/img/contact/weixinCode.png';
import 'antd/dist/antd.css';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message } from "antd";

const Contact = () => {
  return <div className="CONTACTUS">
    <Header index='contact' />
    <div className="CONTACT">CONTACT</div>
    <div className="FROMBEIJING">FROM BEIJING</div>
    <div className="totheWORLD">to the WORLD</div>
    <div className="us">us</div>
    <div className="lxwm">联系我们</div>
    <Line lineHeight={2.11} mt={.25} mb={.61} />

    {/* <div className="WHEREWEARE">
        <div className="WHEREW">WHERE</div>
        <div className="WE">WE</div>
        <div className="ARE">ARE</div>
    </div> */}
    <img src={Whereweare} className='WHEREWEARE' />
    <div className="address">北京市东城区藏经馆胡同2号院D座二层</div>
    {/* <div className="addressEnglish">Tianchangyuan, Chaoyang District, Beijing Building 8 1004</div> */}
    <div className="nameP1">杨依依</div>
    <div className="nameP">M：+86 15501272259</div>
    <div className="nameP">E:yangyiyi@fn-game.cn</div>
    {/* <div style={{height:'2.5rem'}}></div> */}
    <img src={WeChat} className='erco'/>
    <Line lineHeight={1.2} mt={.81} mb={0} />
    <div className="itemCon">
      <CopyToClipboard text="15501272259" onCopy={() => message.success('复制成功')}>
        <div className="item">
          <div className="itemtop"><div style={{width:'.3rem'}}></div>CALL US<div style={{width:'.3rem'}}></div></div>
          <div className="itembottom">电话</div>
        </div>
      </CopyToClipboard>
      <CopyToClipboard text="yangyiyi@fn-game.cn" onCopy={() => message.success('复制成功')}>
        <div className="item">
          <div className="itemtop"><div style={{width:'.3rem'}}></div>EMAIL US<div style={{width:'.3rem'}}></div></div>
          <div className="itembottom">邮件</div>
        </div>
      </CopyToClipboard>
      <CopyToClipboard text="北京市东城区藏经馆胡同2号院D座二层东侧" onCopy={() => message.success('复制成功')}>
        <div className="item">
          <div className="itemtop">GET DIRECRIONS</div>
          <div className="itembottom">地图</div>
        </div>
      </CopyToClipboard>
    </div>
    <BottomView loca='contact' />
    <ToTop red />
  </div>
}

export default Contact;
// todo:Franklin Gothic Book-Italic字体；；；WHEREW；；；ARE
// todo:PalatinoLinotype-Italic字体；；；WE