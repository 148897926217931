import './App.css';
import { Route, Routes, BrowserRouter, HashRouter } from "react-router-dom";
import Index from './pages/index';//首页
import EnterTainment from "./pages/entertainment";//娱乐出行
import PlatForm from './pages/platForm'; //车机平台
import AboutUs from './pages/aboutUs';//关于我们
import Contact from "./pages/contact";//联系我们
import Digital from "./pages/digital";//数字孪生
import Game from "./pages/game";//游戏
function App() {
  return (
    <div className="App">
      {/* <HashRouter>
        <Routes>
          <Route path='/' element={<Index/>} />
          <Route path='/enterTainment' element={<EnterTainment/>} />
          <Route path='/platForm' element={<PlatForm/>} />
          <Route path='/aboutUs' element={<AboutUs/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/digital' element={<Digital/>} />
          <Route path='/game' element={<Game/>} />
        </Routes>
      </HashRouter> */}
      <HashRouter>
        <Routes>
          <Route path='/' exact element={<Index/>} ></Route>
          <Route path='/enterTainment' exact element={<EnterTainment/>} ></Route>
          <Route path='/platForm' exact element={<PlatForm/>} ></Route>
          <Route path='/aboutUs' exact element={<AboutUs/>} ></Route>
          <Route path='/contact' exact element={<Contact/>} ></Route>
          <Route path='/digital' exact element={<Digital/>} ></Route>
          <Route path='/game' exact element={<Game/>} ></Route>
        </Routes>
      </HashRouter>
    </div>
  );
}



export default App;
