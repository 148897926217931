import React,{useState,useEffect} from "react";
import './index.less';
import Line from '../../components/line';
import VideoPlayer from "../../components/player";
import Header from "../../components/header";
import Font2 from '../../assets/img/entertainment/font2.webp';
import Font3 from '../../assets/img/entertainment/font3.webp';
import InfoBlock from "./components/infoBlock";
import Img2 from '../../assets/img/digital/2.webp';
import Img3 from '../../assets/img/digital/3.webp';
import Img4 from '../../assets/img/digital/4.webp';
import Img5 from '../../assets/img/digital/5.webp';
import Img6 from '../../assets/img/digital/6.webp';
import Img7 from '../../assets/img/digital/7.webp';
import Img8 from '../../assets/img/digital/8.webp';
import Img9 from '../../assets/img/digital/9.webp';
import Img10 from '../../assets/img/digital/10.webp';
import Img11 from '../../assets/img/digital/11.webp';
import info1 from '../../assets/img/digital/infoImg/1.webp';
import info3 from '../../assets/img/digital/infoImg/3.webp';
import info4 from '../../assets/img/digital/infoImg/4.webp';
import info5 from '../../assets/img/digital/infoImg/5.webp';
import info6 from '../../assets/img/digital/infoImg/6.webp';
import info7 from '../../assets/img/digital/infoImg/7.webp';
import info8 from '../../assets/img/digital/infoImg/8.webp';
import info9 from '../../assets/img/digital/infoImg/9.webp';
import info10 from '../../assets/img/digital/infoImg/10.webp';
import info11 from '../../assets/img/digital/infoImg/11.webp';
import BottomView from "../../components/bottomView";
import ToTop from "../../components/toTop";

const Digital = () => {
  const [playVideo,setPlayVideo]=useState(false);
  useEffect(()=>{
    document.addEventListener('scroll', handleScroll, true);
  },[])
  const handleScroll = () => {
    // let docItem = document.getElementById('maodian');
    // let hei = docItem.offsetTop
    const scrollTop = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop || 0
    // scrollTop 为距离滚动条顶部高度
    // scrollHeight 为整个文档高度
    // 我们设定当滚动的距离大于 200 时，显示 【返回顶部】按钮
    if(window.scrollY-300<scrollTop){
      console.log('0000000000')
      setPlayVideo(true)
    }
  }
  return <div className="Digital" style={{ backgroudColor: '#EDEDED' }}>
    <Header index='digital' bc='#EDEDED' />
    <div className="CONTACT">THE</div>
    <div className="FROMBEIJING">MASTERS</div>
    <div className="totheWORLD">HUMMINGBIRD</div>
    <div className="Digitaltwin">Digital twin</div>
    <div className="lxwm">数字孪生</div>
    <Line lineHeight={2.11} mt={.25} mb={.61} />
    <div className="qhz">
      <span className="hoverStyle">数字孪生.</span>
      <span className="hoverStyle">智慧地产.</span>
    </div>
    <div className="weHave" style={{ paddingBottom: '.26rem' }}>我们拥有专业的知识、高效的流程和优秀的方案</div>
    <div className="mainCon">
    {/* DigitalTwins.mp4 */}
    <div id='maodian'></div>
      <VideoPlayer toPlay = {playVideo} videoSource={require('../../assets/video/DigitalTwins.mp4')}  />
      <img alt="" src={Font2} style={{ width: '3.92rem', marginTop: '.66rem' }} />
      <Line lineHeight={1.32} mt={.67} mb={.59} />
      <img alt="" src={info1} style={{ width: '12.12rem', marginTop: '.01rem' }} />
      <InfoBlock detail={[]} imgSource={Img2} />
      <Line lineHeight={1.32} mt={.67} mb={.59} />
      <img alt="" src={Font3} style={{height:'4.1rem'}} />
      <Line lineHeight={1.32} mt={.67} mb={.59} />
      <img alt="" src={info7} style={{ height: '2.31rem', marginTop: '.01rem' }} />
      <InfoBlock title='' detail={[]} imgSource={Img3} />
      <Line lineHeight={1.32} mt={.67} mb={.59} />
      <img alt="" src={info3} style={{ height: '2.31rem', marginTop: '.01rem' }} />
      <InfoBlock title='' detail={[]} imgSource={Img4} />
      <Line lineHeight={1.32} mt={.67} mb={.59} />
      <img alt="" src={info4} style={{ height: '2.83rem', marginTop: '.01rem' }} />
      <InfoBlock title='' detail={[]} imgSource={Img5} />
      <Line lineHeight={1.32} mt={.67} mb={.59} />
      <img alt="" src={info5} style={{ height: '2.31rem', marginTop: '.01rem' }} />
      <InfoBlock title='' detail={[]} imgSource={Img6} />
      <Line lineHeight={1.32} mt={.67} mb={.59} />
      <img alt="" src={info10} style={{ height: '2.31rem', marginTop: '.01rem' }} />
      <InfoBlock title='' detail={[]} imgSource={Img7} />
      <Line lineHeight={1.32} mt={.67} mb={.59} />
      <img alt="" src={info6} style={{ height: '1.75rem', marginTop: '.01rem' }} />
      <InfoBlock title='' detail={[]} imgSource={Img8} />
      <Line lineHeight={1.32} mt={.67} mb={.59} />
      <img alt="" src={info11} style={{ height: '2.18rem', marginTop: '.01rem' }} />
      <InfoBlock title='' detail={[]} imgSource={Img9} />
      <Line lineHeight={1.32} mt={.67} mb={.59} />
      <img alt="" src={info8} style={{ height: '2.27rem', marginTop: '.01rem' }} />
      <InfoBlock title='' detail={[]} imgSource={Img10} />
      <Line lineHeight={1.32} mt={.67} mb={.59} />
      <img alt="" src={info9} style={{ height: '2.27rem', marginTop: '.01rem' }} />
      <InfoBlock title='' detail={['']} imgSource={Img11} />
    </div>
    <div className="qhz">
      <span className="hoverStyle">数字孪生.</span>
      <span className="hoverStyle">智慧地产.</span>
      </div>
    <div className="weHave" style={{ paddingBottom: '.26rem' }}>我们拥有专业的知识、高效的流程和优秀的方案</div>
    <BottomView loca='virtual'/>
    <ToTop red/>
  </div>
}








export default Digital;







