import React,{useState, useEffect} from "react";
import './index.less';
import '../../assets/fonts.css';
import Header from "../../components/header";
import Line from "../../components/line";
import SwiperView from "../../components/swiper";
import font1 from '../../assets/img/font1.webp';
import VideoPlayer from "../../components/player";
import swiper11 from '../../assets/img/index/swiper11.webp'
import swiper2 from '../../assets/img/index/swiper2.webp'
import swiper3 from '../../assets/img/index/swiper3.webp'
import swiper4 from '../../assets/img/index/swiper4.webp'
import banner from '../../assets/img/index/banner.webp'
import IndexTextPng from '../../assets/img/index/indexTextPng.webp'
import IndexTextPng2 from '../../assets/img/index/indexTextPng2.webp'
import cooperation from "../../assets/img/cooperation/cooperation";
import CooperationLine from "../../components/coompertion/index";
import BottomView from "../../components/bottomView";
import ToTop from "../../components/toTop";
import IndexBanner from '../../assets/img/index/indexBanner.webp'
import IndexInfo1 from '../../assets/img/index/indexInfo1.webp'
import IndexInfo2 from '../../assets/img/index/indexInfo2.webp'
import IndexInfo3 from '../../assets/img/index/indexInfo3.webp'
import font11 from '../../assets/img/index/1.webp'
import font12 from '../../assets/img/index/2.webp'
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
const Index = (props) => {
  const [playVideo,setPlayVideo]=useState(false);
  const navigate = useNavigate();
  useEffect(()=>{
    document.addEventListener('scroll', handleScroll, true);
  },[])
  const handleScroll = () => {
    console.log('jjjj:::',window.scrollY)
    // let docItem = document.getElementById('maodian1');
    // console.log(docItem)
    // let hei = docItem.offsetTop
    const scrollTop = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop || 0
    // scrollTop 为距离滚动条顶部高度
    // scrollHeight 为整个文档高度
    // 我们设定当滚动的距离大于 200 时，显示 【返回顶部】按钮
    if(window.scrollY-300<scrollTop){
      setPlayVideo(true)
    }
  }
  useEffect(()=>{
    console.log('iiii')
    console.log(playVideo)
  },[playVideo])
  const goPage = (navi)=>{
    if(navi){
      window.scrollTo({
        left: 0,
        top: 0,
        // behavior: 'smooth'
      })
      navigate(navi)
    }
  }
  return <div className="indexStyle">
    <Header index='index' />
    <div className="ESTABLISHED">ESTABLISHED</div>
    <div className="in2013">
      <span className="in">in</span>
      <span className="n2013">2013</span>
    </div>
    <div className="weare">WE ARE</div>
    <div className="HUMMINGBIRD">HUMMINGBIRD</div>
    <div className="fnhd">蜂鸟互动</div>
    <Line lineHeight={1.87} mt={1.94} />
    <SwiperView imgList={[swiper11, swiper2, swiper3, swiper4]} imgH={6.53} />
    <img alt="" src={IndexBanner} style={{width:'100%',marginTop:'-18px'}} />
    <img alt="" src={font11} className='font111' />
    {/* <div className="section1">
      <div className="indexText1">公司成立于2013年</div>
      <div className="indexText1">是一家具有十五年游戏开发基因的内容定制公司</div>
      <div className="indexText1">致力于“车内娱乐内容事业”，“游戏事业”两个业务板块</div>
    </div>
    <div className="section2">
      <div className="indexText2">“车内娱乐内容事业”近年来一直深耕于娱乐技术与汽车驾舱结合的拓展和应用</div>
      <div className="indexText2 text-d">“广汽”《ADiGO WORLD》车机系统游戏</div>
      <div className="undelineCon"><div className="undeline"></div></div>
      <div className="indexText3">“奔驰”EQ400/C200/车内AR解谜游戏</div>
      <div className="indexText3">“奥迪”E-TRON概念车/VR无人驾驶功能测验</div>
      <div className="indexText3">“宝马”VR个性化购车</div>
    </div> */}
    <Line lineHeight={1.45} mt={.79} mb={.2} />
    <img alt="" src={font1} className='font1' />
    <div id='maodian1'></div>
    <VideoPlayer toPlay = {playVideo} videoSource={require('../../assets/video/video1.mp4')} />
    <div className="section4">
      <div className="indexText4">THE</div>
      <div className="indexText4-1">PROJECT</div>
    </div>
    <Line lineHeight={1.45} mt={.76} mb={.98} />
    <img alt="" src={font12} className='font121' />
    {/* <div className="section5">
      <div className="indexText5-1">我们喜欢新的挑战，正是每个项目的独特性激发了我们对事业的热情。</div>
      <div className="indexText5-2">WE LIKE  NEW CHALLENGE  IT’S THE UNIQUENESS OF EACH PROJECT THAT INSPIRES OUR ENTHUSIASM FOR THE CAUSE.</div>
    </div> */}
    <Line lineHeight={1.32} mt={.99} mb={.62} />
    <img alt="" src={IndexInfo1} style={{width:'calc(100% - 1.6rem)',marginLeft:'.8rem',marginRight:'.8rem'}}/>
    <img className="scaleImg" alt="" onClick={()=>{goPage('/game')}} src={IndexInfo2} style={{width:'calc(100% - 1.6rem)',marginLeft:'.7rem',marginRight:'.7rem'}}/>
    {/* <img className="scaleImg" alt="" onClick={()=>{goPage('/digital')}} src={IndexInfo3} style={{width:'calc(100% - 1.6rem)',marginLeft:'.7rem',marginRight:'.7rem'}}/> */}
    {/* <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>
      <ImageTextBlock
        imgSource={Img1}
        imgW={6.91}
        imgH={5.8}
        bigText={['Fn-game', '车机端游戏平台']}
        smallInfoList={['Fn-game游戏平台,是专为前装车机和智能驾舱量身定做的游戏发行平台提供数字版权管理多人游戏、流媒体和社交网络服务等功能，', '用户能下载并自动安装游戏也可以使用包括好友列表和组在内的社区功能，', '前后排屏幕联机，聚会游戏多人同乐，', '车内任意两台屏幕的大厅启动后，可以进行车内局 域网联机游戏也可以与车内相同网络环境其它设 备进行联机对战，', '开机自动识别常用设备，', '平台启动后，主动识别和链接车内的常用设备（手柄、手机、VR头显），', '游戏库类型丰富且全部支持多人联机，', '预计2022年第四季度上线,初装版本包含游戏15个 聚会类游戏3款 竞走类游戏2款 音乐类游戏2款 宠物养成类游戏1款', '棋牌类游戏1款 麻将类游戏1款 棋类游戏1款 跑酷类游戏2款']}
        detail='GAME HALL'
        title='游戏平台'
        mb={2.85}
      />
      <TextImageBlock
        imgSource={Img2}
        imgW={10.79}
        imgH={4.64}
        smallInfoList={['“驾舱娱乐内容事业”近年来一直深耕于娱乐技术与汽车驾舱结合的拓展和应用', '如“广汽”《ADiGO WORLD》车机系统游戏', '“奔 驰”EQ400/C200/车内AR解谜游戏', '“佛吉亚”车机系统游戏', '“奥迪”E-TRON概念车/VR无人驾驶功能测验', '“宝马”VR个性化购车', '“一汽丰田” 奕泽/VR越野驾乘体验', '“一汽红旗”智能小巴/VR驾乘体验', '“哪吒汽车”纯电520U/400U/AR线上虚拟发布会等']}
        detail='Human Machine Interaction(VR)'
        title='车机交互(VR)'
        mb={2.79}
      />
      <ImageTextBlock
        imgSource={Img3}
        imgW={7.56}
        imgH={4.23}
        smallInfoList={['北京蜂鸟互动科技有限公司成立于2013年，与腾讯、网易、畅游等国内游戏企业保持长期良好合作，进行内容产品订制服务', '主要从事游戏开发，美术外包，业务范围涉及客户端游戏、网页端游戏、移动端以及主机平台游戏', '公司创立之初便以“打造行业顶尖游戏产品”为宗旨，核心成员均是从业经历10年以上的资深游戏开发人员', '除传统游戏及应用开发之外，公司目前与VR行业多家知名企业积极展开合作，打造完全独立IP的虚拟现实游戏产品', '北京蜂鸟互动科技有限公司位于北京市朝阳区天畅园8号楼1004', '致力与精品游戏定制开发和虚拟现实内容研发', '与国内一线游戏开发商和运营商都有良好的业务往来', '《全民公主》、《QQ超市2》、《不良人》、《刀剑神域》等项目中蜂鸟互动贡献了美术内容及部分技术支持']}
        detail='GAME'
        title='游戏'
        mb={2.8}
        navi = '/game'
      />
      <TextImageBlock
        imgSource={Img4}
        imgW={9.96}
        imgH={2.91}
        smallInfoList={['公司成立于2013年，以三维GIS、BIM、VR等核心技术为支撑', '实现现实世界事务对象的三维建模', '线上可视化，将现实世界的事务对象的状态等信息', '通过各种设备、传感器等进行实时监测采集，获取其运行状态信息', '基于三维图形引擎及空间', '计算水利技术，为智慧城市、航空航天、 海洋工厂、教育等行业提供优质数字孪生服务']}
        detail='Human Machine Interaction(VR)'
        title='数字孪生'
        mb={0.85}
        navi = '/digital'
      />
    </div> */}
    <div className="SEEALLPROJECTS">SEE ALL PROJECTS</div>
    <img alt="" src={banner} style={{ height: `6.44rem`, width: `100%` }} className='indexBanner' />
    <div className="grayBlock">
      <img alt="" src={IndexTextPng} style={{ width: `2.4rem`, height: '1.11rem' }} />
      <div className="section6">
        <div className="indexText6"><span className='hoverStyle'>智能驾舱.</span><span className='hoverStyle'>车载娱乐.</span><span className='hoverStyle'>车机系统.</span><span className='hoverStyle'>VR娱乐.</span></div>
        <div className="indexText6"><span className='hoverStyle'>汽车元宇宙.</span><span className='hoverStyle'>数字人.</span><span className='hoverStyle'>hmi.</span></div>
        <div className="indexText6"><span className='hoverStyle'>MORE.</span></div>
      </div>
      <div className="section7">
        <div className="indexText7">OUR EXPERTISE</div>
      </div>
      <div className="section8">
        <div className="indexText8-1">THE</div>
        <div className="indexText8-2">COOPERATIVE PARTNER</div>
        <div className="indexText8-3">合作伙伴</div>
      </div>
      <Line lineHeight={2.46} mt={.47} mb={0} />
      <div style={{ height: '.55rem' }}></div>
      <div className="blackBlock">
        <img alt="" src={IndexTextPng2} style={{ width: `2.4rem`, height: '.7rem' }} />
        <div className="COOPERATIVE">VR合作客户VR COOPERATIVE CUSTOMER</div>
        <CooperationLine list={cooperation.line1} />
        <CooperationLine list={cooperation.line2} />
        <CooperationLine list={cooperation.line3} />
        <CooperationLine list={cooperation.line4} />
        <CooperationLine list={cooperation.line5} />
        <div className="COOPERATIVE">游戏合作客户GAME COOPERATIVE CLIENTS</div>
        <CooperationLine list={cooperation.line6} />
        <CooperationLine list={cooperation.line7} />
        <CooperationLine list={cooperation.line8} />
        <CooperationLine list={cooperation.line9} />
        <CooperationLine list={cooperation.line10} last />
      </div>
    </div>
    <BottomView loca='index'/>
    <ToTop/>
  </div>
}
export default Index;