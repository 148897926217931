import React,{useState, useEffect} from "react";
import './index.less';
import Header from "../../components/header";
import Line from "../../components/line";
import VideoPlayer from "../../components/player";
import E2 from '../../assets/img/entertainment/e2.webp';
import Font1 from '../../assets/img/entertainment/font1.webp';//TODO:替换图片
import Font2 from '../../assets/img/entertainment/font2.webp';//TODO:替换图片
import Font3 from '../../assets/img/entertainment/font3.webp';//TODO:替换图片
import BENZ1 from '../../assets/img/entertainment/infoImg/info3.webp';
import BMW1 from '../../assets/img/entertainment/infoImg/info9.webp';
import BMW2 from '../../assets/img/entertainment/infoImg/info10.webp';
import BenzDaimler from '../../assets/img/entertainment/infoImg/info4.webp';
import Aodiime from '../../assets/img/entertainment/infoImg/info5.webp';
import BT from '../../assets/img/entertainment/infoImg/info8.webp';
import BEIJINGXIANDAI from '../../assets/img/entertainment/infoImg/info21.webp';
import BEIQI from '../../assets/img/entertainment/infoImg/info18.webp';
import BYD from '../../assets/img/entertainment/infoImg/info15.webp';
import DONGFENG from '../../assets/img/entertainment/infoImg/info14.webp';
import GQ from '../../assets/img/entertainment/infoImg/info6.webp';
import FORVIA from '../../assets/img/entertainment/infoImg/info22.webp';
import GREATWALL from '../../assets/img/entertainment/infoImg/info16.webp';
import GREATWALLTANK from '../../assets/img/entertainment/infoImg/info17.webp';
import FTVR from '../../assets/img/entertainment/infoImg/info13.webp';
import FTSH from '../../assets/img/entertainment/infoImg/info12.webp';
import GUANGQI2 from '../../assets/img/entertainment/infoImg/info11.webp';
import HQVR from '../../assets/img/entertainment/infoImg/info7.webp';
import HONGQI from '../../assets/img/entertainment/hongqi.webp';
import NEZHA from '../../assets/img/entertainment/infoImg/info20.webp';
import QIRUI from '../../assets/img/entertainment/infoImg/info19.webp';
import Info1 from '../../assets/img/entertainment/infoImg/info1.webp';
import Info2 from '../../assets/img/entertainment/infoImg/info2.webp';
import BottomView from "../../components/bottomView";
import ToTop from "../../components/toTop";

const EnterTainment = ()=>{
  const [playVideo,setPlayVideo]=useState(false);
  useEffect(()=>{
    document.addEventListener('scroll', handleScroll, true);
  },[])
  const handleScroll = () => {
    // let docItem = document.getElementById('maodian2');
    // let hei = docItem.offsetTop
    const scrollTop = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop || 0
    // scrollTop 为距离滚动条顶部高度
    // scrollHeight 为整个文档高度
    // 我们设定当滚动的距离大于 200 时，显示 【返回顶部】按钮
    if(window.scrollY-300<scrollTop){
      console.log('来了')
      setPlayVideo(true)
    }
  }
  useEffect(()=>{
    console.log('iiii')
    console.log(playVideo)
  },[playVideo])
  return <div className="EnterTainment" style={{backgroundColor:'#EDEDED'}}>
    <Header index='enterTainment' bc='#EDEDED' />
    <div className="Enter_the">THE</div>
    <img alt="" src={Font1} style={{width:'1.57rem',height:'.43rem'}}/>
    <div className="Incarentertainment">In car entertainment</div>
    <div className="cnyl">车内娱乐</div>
    <Line lineHeight={1.87} mt={0.25} mb={.3}/>
    <div className="qhz"><span className='hoverStyle'>前装.</span><span className='hoverStyle'>后装.</span></div>
    <div className="weHave">我们拥有专业的知识、高效的流程和优秀的方案</div>
    <div id='maodian2'></div>
    <VideoPlayer toPlay = {playVideo}  videoSource={require('../../assets/video/videoEnterTainment.mp4')} />
    <div>
    <img alt="" src={Font2} style={{width:'3.92rem'}}/>
    </div>
    
    <Line lineHeight={1.32} mt={0.37} mb={.59}/>
    <img alt="" src={Info1} style={{height:'1.94rem',marginBottom:'.6rem'}}/>
    {/* <TwoTextBlock 
      detailList={['“驾舱娱乐内容事业”近年来一直深耕于娱乐技术与汽车驾舱结合的拓展和应用','如“广汽”《ADiGO WORLD》车机系统游戏，“奔 驰”EQ400/C200/车内AR解谜游戏，“佛吉亚”车机系统游戏','“奥迪”E-TRON概念车/VR无人驾驶功能测验，“宝马”VR个性化购车','“一汽丰田” 奕泽/VR越野驾乘体验，“一汽红旗”智能小巴/VR驾乘体验，“哪吒汽车”纯电520U/400U/AR线上虚拟发布会等']}
      mb={.6}
    /> */}
    <img alt="" src={E2} style={{width:'17.557rem',height:'9.01rem'}}/>
    <Line lineHeight={1.32} mt={0.36} mb={.5}/>
    <img alt="" src={Font3} style={{width:'8.02rem',height:'3.01rem'}}/>
    <Line lineHeight={1.32} mt={0.16} mb={.55}/>
    {/* <TwoTextBlock
      title='梅赛德斯奔驰-车内AR娱乐游戏'
      detailList={['使用手机对奔驰EQC内饰扫描进行解谜的AR游戏，在CES展会受到用户广泛好评','车内设置EQC车内饰扫描点，使用手机扫描内饰触发AR交互','利用陀螺仪和触摸操作完成游戏的解密关卡，在CES展会奔驰 展位进行的体验活动获得大量用户欢迎']}
      mb={.6}
    /> */}
    <img alt="" src={BENZ1} style={{width:'17.58rem',height:'11.85rem'}}/>
    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    <img alt="" src={BenzDaimler} style={{width:'17.58rem',height:'11.85rem'}} />
    {/* <TwoTextBlock
      title='戴姆勒奔驰-AR汽车展示'
      detailList={['使用手机对奔驰EQC内饰扫描进行解谜的AR游戏，在CES展会受到用户广泛好评','车内设置EQC车内饰扫描点，使用手机扫描内饰触发AR交互','利用陀螺仪和触摸操作完成游戏的解密关卡，在CES展会奔驰 展位进行的体验活动获得大量用户欢迎']}
      mb={.6}
    />
    <img alt="" src={BENZ2} style={{width:'17.58rem',height:'9rem'}}/> */}
    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    {/* <TwoTextBlock
      title='宝马-4S店个性化购车系统'
      detailList={['增强现实技术在手机或平板展示产品的高精度三维模型','用于4s店的个性化购车演示系统，向客户直观展示车辆内构的分体演示，车门、天窗的开、关效果展示']}
      mb={.6}
    /> */}
    <img alt="" src={BMW1} style={{width:'17.58rem',height:'11.85rem'}}/>
    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    {/* <TwoTextBlock
      title='宝马-数字孪生机械车间'
      detailList={['运用数字孪生技术，将三维虚拟车间与真实车间数据相结合运用三维技术构建','打造一比一还原的真实的制作车间,通过数字孪生的方式在沙盘里即可看到相应生产的东西同时可以追溯到所用的原材料等。']}
      mb={.6}
    /> */}
    <img alt="" src={BMW2} style={{width:'17.58rem',height:'11.85rem'}}/>

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    <img alt="" src={Aodiime} style={{width:'17.58rem',height:'11.35rem'}}/>
    {/* <TwoTextBlock
      title='虚拟奥迪概念车Audi AI: me'
      detailList={['奥迪概念车Audi AI: me真车上市之前体验座舱试驾','奥迪概念车Audi AI: me进行高精度还原，用户通过虚拟现实设备可参观1:1车体内外的造型设计和内部构造']}
      mb={.6}
    />
    <img alt="" src={ANDI1} style={{width:'17.58rem',height:'9.14rem'}}/> */}

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    <img alt="" src={Info2} style={{height:'1.75rem',marginBottom:'.6rem'}}/>
    {/* <TwoTextBlock
      title='佛吉亚-一汽红旗车内娱乐交互系统'
      detailList={['使用车机触摸屏操作的轻度游戏','红旗车内供后座乘客体验的休闲游戏，基于经典游戏《水果忍者》玩法进行创新改装，让游戏操作体验和关卡视野更适合车机屏幕']}
      mb={.6}
    /> */}
    <img alt="" src={HONGQI} style={{width:'17.58rem',height:'9.14rem'}}/>

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    <img alt="" src={GQ} style={{width:'17.58rem',height:'11.35rem'}}/>
    {/* <TwoTextBlock
      title='广汽-Aion LX前装游戏《ADiGO World》'
      detailList={['国内首款将驾驶数据与游戏机制整合的车机养成游戏','搭载于广汽新能源Aion LX车机系统内的生态养成游戏','呼应广汽的节能减排、绿色驾车理念，车主的用车行为数据（驾驶里程、驾驶习惯）影 响游戏内星球和生物的成长变化']}
      mb={.6}
    />
    <img alt="" src={GUANGQI1} style={{width:'17.58rem',height:'9.14rem'}}/> */}

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    {/* <TwoTextBlock
      title='广汽-《ADiGO World》移动端移植版'
      detailList={['沙盒驾驶游戏，拥有自己的虚拟形象，驾驶爱车在星球地表漫游冒险','《ADiGO World》移动设备版，星球变为自由探索的沙盒世界，玩家做任务、收集材料、制造工具和武器，在沙盒世界中进行探索、创造、冒险','移动端与用户的车机数据打通，用户在车机端的游戏数据在移动端继承','每个玩家从性别、发型、五官、服装选项中自由选择生成自己的 虚拟形象，可以在元宇宙的世界中进行冒险体验。']}
      mb={.6}
    /> */}
    <img alt="" src={GUANGQI2} style={{width:'17.58rem',height:'11.4rem'}}/>

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    <img alt="" src={BT} style={{width:'17.58rem',height:'11.2rem'}}/>
    {/* <TwoTextBlock
      title='本田-自主研发多端定位游戏'
      detailList={['丰田新车型与虚拟现实技术相结合的全新驾乘体验','丰田新品车型的真实赛道试驾活动，用户在副驾试乘时佩戴VR头显设备汽车发动的同时虚拟世界中开始横穿多个宇宙的星际冒险','VR世界中用户置身于车型飞行器，穿越不同时空中风景各异的星球，包括：星际战争、冰川星球和末世场景']}
      mb={.6}
    />
    <img alt="" src={HONDA1} style={{width:'17.58rem',height:'9rem'}}/> */}
    
    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    <img alt="" src={FTVR} style={{width:'17.58rem',height:'11.86rem'}}/>
    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    <img alt="" src={FTSH} style={{width:'17.58rem',height:'11.86rem'}}/>
    {/* <TwoTextBlock
      title='丰田-双环赛道VR体验'
      detailList={['丰田新车型与虚拟现实技术相结合的全新驾乘体验','丰田新品车型的真实赛道试驾活动，用户在副驾试乘时佩戴VR头显设备汽车发动的同时虚拟世界中开始横穿多个宇宙的星际冒险','VR世界中用户置身于车型飞行器，穿越不同时空中风景各异的星球，包括：星际战争、冰川星球和末世场景']}
      mb={.6}
    />
    <img alt="" src={HONDA2} style={{width:'17.58rem',height:'9rem'}}/> */}

    {/* <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    <TwoTextBlock
      title='丰田-奕泽VR越野模拟驾驶'
      detailList={['丰田新车型与虚拟现实技术相结合的全新驾乘体验','丰田新品车型的真实赛道试驾活动，用户在副驾试乘时佩戴VR头显设备汽车发动的同时虚拟世界中开始横穿多个宇宙的星际冒险','VR世界中用户置身于车型飞行器，穿越不同时空中风景各异的星球，包括：星际战争、冰川星球和末世场景']}
      mb={.6}
    />
    <img alt="" src={TOYOTA} style={{width:'17.58rem',height:'9rem'}}/> */}

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    {/* <TwoTextBlock
      title='东风-HMI车机界面'
      detailList={['使用UE4引擎开发的车机界面','为郑州跃博-新能源汽车中，多媒体系统车机界面设计，运用UE4引擎','在原有的传统车机界面上加入了更具科技感的设计理念，在视觉及动 态效果中提高了更好的视觉冲击。']}
      mb={.6}
    /> */}
    <img alt="" src={DONGFENG} style={{width:'17.58rem',height:'11.86rem'}}/>

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    {/* <TwoTextBlock
      title='赛车类游戏—东风日产、比亚迪汽车'
      detailList={['支持多种操作方式的车机竞速游戏','多种操作模式满足不同的车机用户游戏习惯，支持方向盘按键、蓝牙游戏手柄、手机虚拟手柄、车机触摸屏']}
      mb={.6}
    /> */}
    <img alt="" src={BYD} style={{width:'17.58rem',height:'11.44rem'}}/>

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    {/* <TwoTextBlock
      title='红旗-VR赛车模拟器'
      detailList={['真实车辆设备与虚拟现实相结合的赛车模拟器','真车的方向盘、刹车、油门与VR游戏打通,使用汽车真车方向盘控制游戏操作','玩家可在虚拟世界驾驶自己的爱车无限驰聘。车联网技术将 实车的功能应用于游戏内，玩家在游戏内能够获得和真车完全的驾驶、车机功能体验']}
      mb={.6}
    /> */}
    <img alt="" src={HQVR} style={{width:'17.58rem',height:'11.86rem'}}/>

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    {/* <TwoTextBlock
      title='长城-欧拉猫数字人驾驶助手'
      detailList={['长城汽车“欧拉猫”系列，车机系统搭载智能语音助手','长城欧拉猫系列的数字人形象，伴随着车主在行驶过程中进行语音反馈和提示']}
      mb={.6}
    /> */}
    <img alt="" src={GREATWALL} style={{width:'17.58rem',height:'11.44rem'}}/>

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    {/* <TwoTextBlock
      title='长城-坦克300数字人驾驶助手'
      detailList={['长城汽车“坦克300”系列，车机系统搭载智能语音助手','不同的车型搭配不同性别助手，驾车过程中伴随车主的驾驶操作，进行语音反馈和意见提示']}
      mb={.6}
    /> */}
    <img alt="" src={GREATWALLTANK} style={{width:'17.58rem',height:'11.44rem'}}/>

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    {/* <TwoTextBlock
      title='北汽-VR-HMI车机界面'
      detailList={['HMI-人机界面人与计算机之间传递、交换信息的媒介和对话接口','系统和用户之间进行交互和信息交换的媒介，实现信息的内部形式与人类可以接受形式之间的转换']}
      mb={.6}
    /> */}
    <img alt="" src={BEIQI} style={{width:'17.58rem',height:'11.44rem'}}/>

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    {/* <TwoTextBlock
      title='奇瑞-VR驾驶体验'
      detailList={['用户驾驶奇瑞汽车从未来城市的机库内出发，穿越未来城市后进入虫洞体验宇宙星河、机械文明空间站','最后穿越回未来城市全程体验1分钟左右流程中场景涉及未来城市机库、宇宙星河、机械文明空间站。']}
      mb={.6}
    /> */}
    <img alt="" src={QIRUI} style={{width:'17.58rem',height:'11.44rem'}}/>

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    {/* <TwoTextBlock
      title='哪吒汽车-AR直播虚拟线上发布会'
      detailList={['哪吒U520U/400U新车线上发布会三维技术构建的虚拟发布会舞台与真人影像进行合成','随着发布会流程切换不同的主题特效把真实拍摄的车辆影像数据投射 到舞台之中，从多个维度展示哪吒U系列产品内外细节']}
      mb={.6}
    /> */}
    <img alt="" src={NEZHA} style={{width:'17.58rem',height:'11.44rem'}}/>

    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    {/* <TwoTextBlock
      title='北京现代-AR直播虚拟线上发布会'
      detailList={['使用手机对内饰扫描进行运用AR展示，在发布会上受到用户广泛好评','车内设置内饰扫描点，使用手机扫描内饰触发AR交互，利用陀螺仪和触摸操作']}
      mb={.6}
    /> */}
    <img alt="" src={BEIJINGXIANDAI} style={{width:'17.58rem',height:'11.44rem'}}/>

    {/* <Line lineHeight={1.32} mt={0.55} mb={.55}/> */}
    {/* <TwoTextBlock
      title='FAURECIA-体感游戏'
      detailList={['用身体去感受的电子游戏。突破以往单纯以手柄按键输入的操作方式','体感游戏是一种通过肢体动作变化来进行操作的新型电子游戏']}
      mb={.6}
    /> */}
    <img alt="" src={FORVIA} style={{width:'17.58rem',height:'13.13rem',marginTop:'.45rem'}}/>
    <Line lineHeight={1.32} mt={0.55} mb={.55}/>
    <div className="qhz"><span className='hoverStyle'>前装.</span><span className='hoverStyle'>后装.</span></div>
    <div className="weHave" style={{marginBottom:'.59rem'}}>我们拥有专业的知识、高效的流程和优秀的方案</div>
    <BottomView loca='platform'/>
    <ToTop/>
  </div>
}

export default EnterTainment;