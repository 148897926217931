import React from "react";
import './index.less';

const YearBlock = (props)=>{
  const {imgSource} = props;
  return <div className="yearImg">
      <img src={imgSource} className='year'/>
  </div>
}

export default YearBlock;