import './index.less';
import ToTopImg from '../../assets/img/totop.webp';
import ToTopImg2 from '../../assets/img/totop.png';
import { useState, useEffect } from "react";

const ToTop = (props) => {
  let {red} = props
  // 定义 visibleBackTopBtn 变量控制 返回顶部 按钮的显隐
  const [visibleBackTopBtn, setVisibleBackTopBtn] = useState(false)

  useEffect(() => {
    // 在 React 中使用 addEventListener 监听事件
    document.addEventListener('scroll', handleScroll, true);
    // 组件卸载时移除事件监听
    return () => document.removeEventListener('scroll', handleScroll)
  }, [visibleBackTopBtn])
  // 滚动事件监听函数
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop || 0
    // scrollTop 为距离滚动条顶部高度
    // scrollHeight 为整个文档高度

    // 我们设定当滚动的距离大于 200 时，显示 【返回顶部】按钮
    if (scrollTop > 200) {
      setVisibleBackTopBtn(true)
    } else {
      setVisibleBackTopBtn(false)
    }
  }
  // 点击按钮事件处理函数
  const backToTopHandle = () => {
    // 把页面滚动到页面顶部
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    })
  }
  return <div className='totop'>
    {visibleBackTopBtn ? red?<img className='ToTopImg' src={ToTopImg2} onClick={() => { backToTopHandle() }} />:<img className='ToTopImg' src={ToTopImg} onClick={() => { backToTopHandle() }} /> : null}

  </div>
}

export default ToTop;