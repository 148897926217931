import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './utils/rem';
import wx from 'weixin-js-sdk'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


<script src="http://res2.wx.qq.com/open/js/jweixin-1.6.0.js" type="text/javascript"></script>


function getSignature(){
	
	let accessKey = "TYJHXBnufwgenqAw2CLHUEpFXLMgNN8CFP";
	let accessSecret = "53DD28070E4EE8EE524A2DF83A21853CA839369ACCD446D1137949FBD9FB6425";
	let noncestr = 'wwwww';
	let timestamp = '1234567';
	let url = 'http://fn-game.cn/';
	

	
	

	
	
	let par = 'noncestr:'+noncestr+'*timestamp:'+timestamp;
	let signature = 'fngame '+accessKey+':'+binb2b64(core_hmac_sha1(accessSecret,par));
	//hsykhGi4uAsjRmLazK+DG4Hmj5o=
	//hsykhGi4uAsjRmLazK+DG4Hmj5o=
	
	// console.log("---------> "+signature);			
	
	
	
	
	
	 fetch('http://39.98.41.96:9226/share/getSignature',{
	            method:'post',
	            headers:{
	                'Accept':'application/json,text/plain,*/*',/* 格式限制：json、文本、其他格式 */
	                'Content-Type':'application/x-www-form-urlencoded',/*, 请求内容类型 */
					'noncestr':noncestr,/* 请求内容类型 */
					'timestamp':timestamp,/* 请求内容类型 */
					'url':url,/* 请求内容类型 */
					'signature':signature
					   
	            },
	            body:{}
	        }).then((response)=>{
				if(response.status == 200){
					var _data = response.json();
					_data.then(function(value){
						setTimeout(function() {
						        // console.log(value.data);
								wx.config({
								beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
								debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
								appId: 'wx711bfc2c5beeb3e5', // 必填，企业微信的corpID
								timestamp: timestamp, // 必填，生成签名的时间戳 —>单位：秒
								nonceStr: noncestr, // 必填，生成签名的随机串—>长度一般不超过32，格式建议[0-9a-zA-z]
								signature: value.data,// 必填，签名，见 微信公众号开发文档附录JS-SDK使用权限签名算法
								jsApiList: ['updateTimelineShareData','updateAppMessageShareData'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
								});
								
								wx.ready(function () {      //需在用户可能点击分享按钮前就先调用
								  wx.updateAppMessageShareData({ 
								    title: '蜂鸟互动', // 分享标题
									desc: '北京蜂鸟互动科技有限公司，是一家具有十五年游戏开发基因的内容研发公司。', // 分享描述
								    link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
								    imgUrl: 'http://fn-game.cn/fnGameLogo.png', // 分享图标
								    success: function () {
								      // 设置成功
									 // console.log("分享成功");
								    },
									cancel:function(){
										// console.log("取消分享");
									}
								  })
								}); 
								wx.ready(function () {      //需在用户可能点击分享按钮前就先调用
								  wx.updateTimelineShareData({ 
								    title: '蜂鸟互动', // 分享标题
								    link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
								    imgUrl: 'http://fn-game.cn/fnGameLogo.png', // 分享图标
								    success: function () {
								      // 设置成功
								    }
								  })
								}); 
								
						    }, 0);
					});
				}
	            // return response.json()
	        }).then((data)=>{
	            console.log(data)
	        }).catch(function(error){
	            console.log(error)
	        })

}

// -------------签名---------------

var hexcase = 0;
var b64pad = "";
var chrsz = 8;

function hex_sha1(s) {
	return binb2hex(core_sha1(str2binb(s), s.length * chrsz))
}

function b64_sha1(s) {
	return binb2b64(core_sha1(str2binb(s), s.length * chrsz))
}

function str_sha1(s) {
	return binb2str(core_sha1(str2binb(s), s.length * chrsz))
}

function hex_hmac_sha1(key, data) {
	return binb2hex(core_hmac_sha1(key, data))
}

function b64_hmac_sha1(key, data) {
	return binb2b64(core_hmac_sha1(key, data))
}

function str_hmac_sha1(key, data) {
	return binb2str(core_hmac_sha1(key, data))
}

function sha1_vm_test() {
	return hex_sha1("abc") == "a9993e364706816aba3e25717850c26c9cd0d89d"
}

function core_sha1(x, len) {
	x[len >> 5] |= 128 << (24 - len % 32);
	x[((len + 64 >> 9) << 4) + 15] = len;
	var w = Array(80);
	var a = 1732584193;
	var b = -271733879;
	var c = -1732584194;
	var d = 271733878;
	var e = -1009589776;
	for (var i = 0; i < x.length; i += 16) {
		var olda = a;
		var oldb = b;
		var oldc = c;
		var oldd = d;
		var olde = e;
		for (var j = 0; j < 80; j++) {
			if (j < 16) {
				w[j] = x[i + j]
			} else {
				w[j] = rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1)
			}
			var t = safe_add(safe_add(rol(a, 5), sha1_ft(j, b, c, d)), safe_add(safe_add(e, w[j]), sha1_kt(j)));
			e = d;
			d = c;
			c = rol(b, 30);
			b = a;
			a = t
		}
		a = safe_add(a, olda);
		b = safe_add(b, oldb);
		c = safe_add(c, oldc);
		d = safe_add(d, oldd);
		e = safe_add(e, olde)
	}
	return Array(a, b, c, d, e)
}

function sha1_ft(t, b, c, d) {
	if (t < 20) {
		return (b & c) | ((~b) & d)
	}
	if (t < 40) {
		return b ^ c ^ d
	}
	if (t < 60) {
		return (b & c) | (b & d) | (c & d)
	}
	return b ^ c ^ d
}

function sha1_kt(t) {
	return (t < 20) ? 1518500249 : (t < 40) ? 1859775393 : (t < 60) ? -1894007588 : -899497514
}

function core_hmac_sha1(key, data) {
	var bkey = str2binb(key);
	if (bkey.length > 16) {
		bkey = core_sha1(bkey, key.length * chrsz)
	}
	var ipad = Array(16),
		opad = Array(16);
	for (var i = 0; i < 16; i++) {
		ipad[i] = bkey[i] ^ 909522486;
		opad[i] = bkey[i] ^ 1549556828
	}
	var hash = core_sha1(ipad.concat(str2binb(data)), 512 + data.length * chrsz);
	return core_sha1(opad.concat(hash), 512 + 160)
}

function safe_add(x, y) {
	var lsw = (x & 65535) + (y & 65535);
	var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
	return (msw << 16) | (lsw & 65535)
}

function rol(num, cnt) {
	return (num << cnt) | (num >>> (32 - cnt))
}

function str2binb(str) {
	var bin = Array();
	var mask = (1 << chrsz) - 1;
	for (var i = 0; i < str.length * chrsz; i += chrsz) {
		bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - i % 32)
	}
	return bin
}

function binb2str(bin) {
	var str = "";
	var mask = (1 << chrsz) - 1;
	for (var i = 0; i < bin.length * 32; i += chrsz) {
		str += String.fromCharCode((bin[i >> 5] >>> (24 - i % 32)) & mask)
	}
	return str
}

function binb2hex(binarray) {
	var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
	var str = "";
	for (var i = 0; i < binarray.length * 4; i++) {
		str += hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8 + 4)) & 15) + hex_tab.charAt((binarray[i >> 2] >> ((
			3 - i % 4) * 8)) & 15)
	}
	return str
}

function binb2b64(binarray) {
	var tab = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
	var str = "";
	for (var i = 0; i < binarray.length * 4; i += 3) {
		var triplet = (((binarray[i >> 2] >> 8 * (3 - i % 4)) & 255) << 16) | (((binarray[i + 1 >> 2] >> 8 * (3 - (i +
			1) % 4)) & 255) << 8) | ((binarray[i + 2 >> 2] >> 8 * (3 - (i + 2) % 4)) & 255);
		for (var j = 0; j < 4; j++) {
			if (i * 8 + j * 6 > binarray.length * 32) {
				str += b64pad
			} else {
				str += tab.charAt((triplet >> 6 * (3 - j)) & 63)
			}
		}
	}
	return str+"=";
};

// --------------end--------------

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

getSignature();
reportWebVitals();
