import React from "react";
// import cooperation from "../../assets/img/cooperation/cooperation";
import './index.css';

const CooperationLine = (props) => {
  const { list ,last} = props;
  return <div className="CooperationLineCon" style={last?{paddingBottom:0}:null}>
    {
      list.map((item, index) => {
        return <div key={index} style={{width:'17%'}}><img style={{width:'100%'}} src={item} /></div>
      })
    }
  </div>
}
export default CooperationLine;