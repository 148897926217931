import React, { useState } from "react";
import './index.css';
import { useNavigate } from "react-router-dom";
import erCode from '../../assets/img/erCode.webp';
import biao from '../../assets/img/biao.png';

const BottomView = (props) => {
  const navigate = useNavigate();
  const { loca } = props;
  const [showCode, setShowCode] = useState(false)
  const activeTo = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    })
  }
  const change = () => {
    let i = !showCode;
    setShowCode(i)
  }
  return <div className="BottomView">
    <div className="RELATIONS">RELATIONS that MATTER</div>
    <div className="bottomText1">WE ARE COMMITTED TO CREATING</div>
    <div className="bottomText1">QUALITY AND HAPPINESS</div>
    <div className="pph">
      <div className="pphItem" onClick={() => { activeTo(); navigate('/contact') }}>
        <div className="pphItemT">LET'S PARTNER</div>
        <div className="pphItemB">合作</div>
      </div>
      <div className="pphItem" onClick={() => { activeTo(); navigate('/enterTainment') }}>
        <div className="pphItemT">I HAVE A PROJECT</div>
        <div className="pphItemB">项目</div>
      </div>
      <div className="pphItem">
        <div className="pphItemT">I NEED SOME HELP</div>
        <div className="pphItemB">帮助</div>
      </div>
    </div>
    <div className="happy">我们致力于创造品质，创造快乐</div>
    <div className="funny">我们期待，在未来可以与更多的优秀伙伴合作出，更有趣，精彩的游戏带给玩家</div>
    <div className='connectUs'><div className="pphItemT" onClick={() => { activeTo(); navigate('/contact') }}>联系我们</div></div>
    <div className="EXPLORECon">
      <div className="firstLine">
        <div className="EXPLORE">EXPLORE</div>
        <div className="TITTLE">TITTLE</div>
      </div>
      <div className="secondLine" onClick={() => { activeTo(); navigate('/') }} >
        <div className="e">H</div>
        <div className="t">HOME</div>
        <div className="th">首页</div>
      </div>
      <div className="secondLine" onClick={() => { activeTo(); navigate('/enterTainment') }} >
        <div className="e">I</div>
        <div className="t">IN CAR ENTERTAINMENT</div>
        <div className="th">娱乐出行</div>
      </div>
      <div className="secondLine" onClick={() => { activeTo(); navigate('/platForm') }}>
        <div className="e">C</div>
        <div className="t">CAR GAME PLATFORM</div>
        <div className="th">车机平台</div>
      </div>
      {/* <div className="secondLine" onClick={() => { activeTo(); navigate('/digital') }}>
        <div className="e">D</div>
        <div className="t">DIGITAL TWINS</div>
        <div className="th">数字孪生</div>
      </div> */}
      <div className="secondLine" onClick={() => { activeTo(); navigate('/game') }}>
        <div className="e">G</div>
        <div className="t">GAME PROJECT</div>
        <div className="th">游戏项目</div>
      </div>
      <div className="secondLine" onClick={() => { activeTo(); navigate('/contact') }}>
        <div className="e">C</div>
        <div className="t">CONTACT US</div>
        <div className="th">联系我们</div>
      </div>
      <div className="secondLine" onClick={() => { activeTo(); navigate('/aboutUs') }}>
        <div className="e">A</div>
        <div className="t">ABOUT US</div>
        <div className="th">关于我们</div>
      </div>
    </div>
    <div className='contactTool'>
      <div className="toolCon">
        <a href="https://weibo.com/u/3902014869" target='_blank'>
          <div className="tool" >微博</div>
        </a>
        <div className="di"></div>
        <div className="tool" onClick={() => { change() }} >微信</div>
        <div className="di"></div>
        <div className="tool" onClick={() => { activeTo(); navigate('/contact') }} >邮箱</div>
        {showCode ? <img src={erCode} className='code' /> : null}
      </div>
      <div className="beianCon">
        <div className="toolCon2" style={{ marginTop: 0 }}>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target='_blank'>
            <div className="beian" style={{ marginTop: 0 }}>京ICP备16001227号-1</div>
          </a>
        </div>
        <div className="toolCon" style={{marginLeft:'20px'}}>
          <a className="toolCon2" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102006398" target='_blank'>
            <img src={biao} />
            <div className="beian" style={{ marginTop: 0, marginLeft: '10px' }} >京公网安备 11010102006398号</div>
          </a>
        </div>
      </div>

    </div>
  </div>
}

export default BottomView;