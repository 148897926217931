import './index.less';

const InfoBlock = (props)=> {
  const {title,detail,imgSource} = props;
  return <div className='InfoBlock'>
      {
        title?<div className='title'>{title}</div>:null
      }
      <div className='detailCon'>
        {
          detail.map((res,index)=>{
            return <div className='detail' key={index}>{res}</div>
          })
        }
      </div>
      <img src={imgSource} className='imgSource'/>
  </div>
}

export default InfoBlock;