import React from "react";
import './index.css'

const Line = (props)=>{
  /**
   * lineHeight: 线高
   * mt:上边距
   * mb:下边距
   */
  const {lineHeight,mt,mb,bc} = props;
  return <div className="lineCon" style={{marginTop:`${mt}rem`,marginBottom:`${mb}rem`}}>
      <div className="line" style={{height:`${lineHeight}rem`,backgroundColor:bc?bc:'#000',width:'1px'}}></div>
  </div>
  
}
export default Line