import React from "react";
import './index.less';
import HeaderFFF from "../../components/headerFFF/index";
import SwiperView from "./components/swiper";
import SwiperViewSmall from "./components/swiperSmall";
import Line from "../../components/line";
import GameSwiper1 from '../../assets/img/platForm/gameSwiper1.webp';
import GameSwiper2 from '../../assets/img/platForm/gameSwiper2.webp';
import GameSwiper3 from '../../assets/img/platForm/gameSwiper3.webp';
import GameSwiper4 from '../../assets/img/platForm/gameSwiper4.webp';
import GameSwiper5 from '../../assets/img/platForm/gameSwiper5.webp';
import GameSwiper1_1 from '../../assets/img/platForm/gameSwiper1_1.webp';
import GameSwiper2_1 from '../../assets/img/platForm/gameSwiper2_1.webp';
import GameSwiper3_1 from '../../assets/img/platForm/gameSwiper3_1.webp';
import Font11 from '../../assets/img/entertainment/font1.webp';
import Image1 from '../../assets/img/platForm/1.webp';
import Image2 from '../../assets/img/platForm/2.webp';
import Image3 from '../../assets/img/platForm/3.webp';
import Image4 from '../../assets/img/platForm/4.webp';
import Image5 from '../../assets/img/platForm/5.webp';
import Image6 from '../../assets/img/platForm/6.webp';
import Image7 from '../../assets/img/platForm/7.webp';
import Image8 from '../../assets/img/platForm/8.webp';
import Image9 from '../../assets/img/platForm/9.webp';
import Image10 from '../../assets/img/platForm/10.webp';
import Image11 from '../../assets/img/platForm/11.webp';
import Image12 from '../../assets/img/platForm/12.webp';
import Image13 from '../../assets/img/platForm/13.webp';
import Image14 from '../../assets/img/platForm/14.webp';
import Image15 from '../../assets/img/platForm/15.webp';
import Image16 from '../../assets/img/platForm/16.webp';
import Image17 from '../../assets/img/platForm/17.webp';
import Image18 from '../../assets/img/platForm/18.webp';
import Image19 from '../../assets/img/platForm/19.webp';
import Image20 from '../../assets/img/platForm/20.webp';
import Image21 from '../../assets/img/platForm/21.webp';
import Image22 from '../../assets/img/platForm/22.webp';
import Image23 from '../../assets/img/platForm/23.webp';
import Image24 from '../../assets/img/platForm/24.webp';
import Image25 from '../../assets/img/platForm/25.webp';
import Image26 from '../../assets/img/platForm/26.webp';
import Image27 from '../../assets/img/platForm/27.webp';
import Image28 from '../../assets/img/platForm/28.webp';
import Image29 from '../../assets/img/platForm/29.webp';
import Image30 from '../../assets/img/platForm/30.webp';
import Image31 from '../../assets/img/platForm/31.webp';
import Image32 from '../../assets/img/platForm/32.webp';
import Image33 from '../../assets/img/platForm/33.webp';
import Image34 from '../../assets/img/platForm/34.webp';
import LBS from '../../assets/img/platForm/LBS.webp';
import Font1 from '../../assets/img/font12.webp';
import InfoImage1 from '../../assets/img/platForm/infoImg/1.webp';
import InfoImage2 from '../../assets/img/platForm/infoImg/2.webp';
import InfoImage3 from '../../assets/img/platForm/infoImg/3.webp';
import InfoImage4 from '../../assets/img/platForm/infoImg/4.webp';
import InfoImage6 from '../../assets/img/platForm/infoImg/6.webp';
import InfoImage7 from '../../assets/img/platForm/infoImg/7.webp';
import InfoImage9 from '../../assets/img/platForm/infoImg/9.webp';
import InfoImage11 from '../../assets/img/platForm/infoImg/11.webp';
import SmallBlock from "./components/smallBlock";
import InfoBlock from './components/infoBlock';
import ImageList from "../game/components/imgList";
import BlockWithLine from "./components/blockWithLine";
import BottomView from "../../components/bottomView";
import ToTop from "../../components/toTop";

const PlatForm = () => {
  return <div className="platForm">
    <HeaderFFF index='platForm' bc='#000' />
    <div className="blackBlockPlat">
      <div className="ESTABLISHED">ESTABLISHED</div>
      <div className="in2013">
        <span className="in">in</span>
        <span className="n2013">2022</span>
      </div>
      <div className="fn">FN-GAME</div>
      <div className="fn">GAME HALL</div>
      <div className="yxpt">游戏平台</div>
      <Line lineHeight={.365} mt={.25} mb={.47} bc='#fff' />
      <div className="cjyxpt">车机端游戏平台</div>
      <div className="cjyxptx">覆盖前装、副驾、后排屏幕的多人聚会游戏平台</div>
    </div>
    <div className="blockCon">
      <SmallBlock text1='15' text2='款游戏' />
      <SmallBlock text1='N+' text2='款游戏' />
      <SmallBlock text1='30' text2='款游戏' text3='预计第一年更新' />
      <SmallBlock text1='>100' text2='款游戏' text3='第二年' />
    </div>

    <SwiperView imgList={[GameSwiper2, GameSwiper4, GameSwiper1, GameSwiper3 ]} imgH={11.96} />
    
    <div className="ogs">
      <div className="og">OUR GAME</div>
      <div className="s">游戏展示</div>
    </div>
    <SwiperViewSmall imgList={[GameSwiper1_1, GameSwiper2_1, GameSwiper3_1]} imgH={7.31} />
    {/* //todo:滑动部分 */}
    <Line lineHeight={1.32} mt={.25} mb={.34} />
    {/* <InfoBlock title='FN-GAME车机端游戏平台' detail={['覆盖前装、副驾、后排屏幕的多人聚会游戏平台', 'Fn-game游戏平台,是专为前装车机和智能驾舱量身定做的游戏发行平台，提供数字版权管理、多人游戏、流媒体和社交网络服务等功能', '用户能下载并自动安装游戏，也可以使用包括好友列表和组在内的社区功能']} /> */}
    <img alt="" src={InfoImage1} style={{height:'2.21rem',marginBottom:'.1rem'}} />
    <img alt="" src={Font1} className='jsj' />
    <Line lineHeight={1.32} mt={.11} mb={.36} />
    {/* <InfoBlock title='前后排屏幕联机，聚会游戏多人同乐' detail={['车内任意两台屏幕的大厅启动后，可以进行车内局 域网联机游戏，也可以与车内相同网络环境其它设 备进行联机对战']} imageH={6.54} /> */}
    <img alt="" src={InfoImage2} style={{height:'1.07rem',marginBottom:'.3rem'}} />
    <ImageList list={[Image1]} />
    <Line lineHeight={1.32} mt={.39} mb={.38} />
    <InfoBlock title='开机自动识别常用设备' detail={['平台启动后，主动识别和链接车内的常用设备（手柄、手机、VR头显）']} imageH={6.54} />
    <ImageList list={[Image2, Image3, Image4]} />
    <Line lineHeight={1.32} mt={.39} mb={.38} />
    {/* <InfoBlock title='日常驾驶数据用于购买游戏虚拟商品' detail={['游戏大厅与车机数据打通,通过积累驾驶,充电、历程 数据,转换为大厅的虚拟积分,用于购买游戏的虚拟道 具,或解锁更多游戏内容']} imageH={6.54} /> */}
    <img alt="" src={InfoImage3} style={{height:'1.04rem',marginBottom:'.3rem'}} />
    <ImageList list={[Image5]} />
   
    {/* <InfoBlock title='海量游戏内容订阅下载' detail={['付费订阅会员享受更多的独占游戏和内容,Fn-game游戏平台预安装在车机系统中车辆品牌 账号登陆即享有使用权；平台上的游戏全部免费', '所有用户均可拥有下载以及使用权; 平台付费订阅制度,订阅用户每个月可获得额外专属 游戏,享受特殊头像皮肤等增值内容']} imageH={6.54} /> */}
    <img src={LBS} style={{height:'13.23rem',marginTop:'.39rem'}}/>
     <Line lineHeight={1.32} mt={.39} mb={.38} />
    <img alt="" src={InfoImage4} style={{height:'1.6rem',marginBottom:'.3rem'}} />
    
    <ImageList list={[Image6, Image7, Image8]} />
    <Line lineHeight={1.32} mt={.39} mb={.38} />
    {/* <InfoBlock title='游戏库类型丰富且全部支持多人联机' detail={['预计2022年第四季度上线,', '初装版本包含游戏15个 聚会类游戏3款 竞速类游戏2款 音乐类游戏2款 宠物养成类游戏1款', '棋牌类游戏1款 麻将类游戏1款 棋类游戏1款 射击类游戏2款 跑酷类游戏2款']} imageH={6.54} /> */}
    <img alt="" src={InfoImage6} style={{height:'2.13rem',marginBottom:'.3rem'}} />
    <ImageList list={[Image9, Image10, Image11]} />
    
    {/* 二期更新 */}
    <img alt="" src={Font1} className='jsj' />
    <Line lineHeight={1.32} mt={.39} mb={.38} />
    {/* <ImageList list={[Image32]} /> */}
    <img src={Image32} className='img6542'/>
    <Line lineHeight={1.32} mt={.39} mb={.38} />
    {/* <ImageList list={[Image33]} /> */}
    <img src={Image33} className='img6541'/>
    <Line lineHeight={1.32} mt={.39} mb={.38} />
    {/* <ImageList list={[Image34]} /> */}
    <img src={Image34} className='img6541'/>
    
    <div className="zBlock">
      <div className="GAME">GAME</div>
      <img alt="" src={Font11} style={{ width: '1.57rem', height: '.43rem' }} />
      <div className="GAME">DISPLAY</div>
      <BlockWithLine title='' detail={[]} />
      <img alt="" src={InfoImage7} style={{height:'1.97rem',marginBottom:'.3rem',marginTop:'.1rem'}} />
      <ImageList list={[Image12, Image13, Image14, Image15]} />
      <BlockWithLine title='极限漂移' detail={[]} />
      <ImageList list={[Image16, Image17, Image18, Image19]} />
      {/* <BlockWithLine title='迷失黑洞' detail={['简洁易上手，指尖操作畅爽游戏在游戏中玩家可以选择各种模式进行战斗，竞速、无限、多人模式趣味无穷；享受破坏带来的乐趣，不断的壮大自己，吞噬吧', '吞噬掉一切东西。 游戏是一款玩法新颖创意精彩的大作战类多人对抗游戏，玩家会以黑洞的加入到战场之中，吞噬你眼前看到的一切来不断增强自身力量', '扩大黑洞范围让你拥有更强劲的吸力，提高成长指数获得最高排名，时间结束即可获得最终胜利。']} /> */}
      <img alt="" src={InfoImage9} style={{height:'1.97rem',marginBottom:'.3rem',marginTop:'.3rem'}} />
      <ImageList list={[Image20, Image21, Image22, Image23]} />
      <BlockWithLine title='熊猫麻将' detail={[]} />
      <ImageList list={[Image24, Image25, Image26, Image27]} />
      {/* <BlockWithLine title='玩具军团' detail={['在这里，你将使用最有趣的英雄,在派对进行精彩对决。攻击、跳跃、闪避、防御.……花式玩法，将敌人击出场外！', '数十种各具特色的创意英雄供你选择，还有更多新角色等你一起解锁。', '找到属于你的战斗风格，下一个派对之星就是你。']} /> */}
      <img alt="" src={InfoImage11} style={{height:'1.97rem',marginBottom:'.3rem',marginTop:'.3rem'}} />
      <ImageList list={[Image28, Image29, Image30, Image31]} />
      <div className="qhz" style={{marginTop:'.4rem'}}><span className='hoverStyle'>RPG.</span><span className='hoverStyle'>冒险.</span><span className='hoverStyle'>赛车.</span><span className='hoverStyle'>休闲.</span></div>
      <div className="weHave">我们拥有专业的知识、高效的流程和优秀的方案</div>
    </div>
    <BottomView loca='platform' />
    <ToTop/>
  </div>
}

export default PlatForm;