import './index.less';

const BlockWithLine = (props)=>{
  const {title,detail} = props;
  return <div className='BlockWithLine'>
    <div className='mainCon'>
    {
      title?
      <div className='title'>{title}</div>:null}
      <div className='detailCon'>
        {
          detail.map((res,index)=>{
            return <div className='detail' key={index}>{res}</div>
          })
        }
      </div>
    </div>
    
    
  </div>
}

export default BlockWithLine