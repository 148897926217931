import './index.less';

const SmallBlock = (props)=>{
  const {text1,text2,text3} = props;
  return <div className='SmallBlock'>
      <div className='text1'>{text1}</div>
      <div className='text2'>{text2}</div>
      {
        text3?<div className='text2'>{text3}</div>:null
      }
      
  </div>
}

export default SmallBlock;