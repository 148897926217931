import React from "react";
import './index.less';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay,FreeMode } from "swiper";
// import './swiper.min.css'
import "swiper/css";
import "swiper/css/pagination";

const SwiperViewSmall = (props) => {
  const {imgList,imgH} = props;
  return <div className="swiperSmallCon">
    <Swiper
      modules={[Pagination, Scrollbar, Autoplay, Navigation, A11y,FreeMode]}
      pagination={false}
      spaceBetween={30}
      slidesPerView={1.3}
      freeMode={true}
      // slidesPerView='auto'
      // navigation={{ enabled: true }}
      // Pagination={{ enabled: true, clickable: true, el: '.swiper-pagination' }}
      // scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      // autoplay={{
      //   delay: 1000,
      //   disableOnInteraction: false,
      // }}
      loop
    >
      {
        imgList.map((img,index)=>{
          return <SwiperSlide key={index}><img src={img} style={{height:`${imgH}rem`}}/></SwiperSlide>
        })
      }
    </Swiper>
  </div>
}

export default SwiperViewSmall;