import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Logo from '../../assets/img/logo.webp';
import More from '../../assets/img/more.webp';
import More1 from '../../assets/img/more1.webp';
import './index.less'

const Header = (props) => {
  const { index, bc } = props;
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [isPhone, setIsPhone] = useState(true);
  useEffect(()=>{
    let a = test();
    console.log('kskksk:',a)
    if(a){
      setIsPhone(true);
    }else{
      setIsPhone(false);
    }
    
  },[])
  useEffect(()=>{
    console.log('是否是电话:',isPhone)
  },[isPhone])
  const test = ()=>{
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    return flag;
  }
  return <div className="headerCon" style={bc ? { backgroundColor: bc } : null}>
    <img alt="" src={Logo} className='logo' />
    <div className="right">
      {
        show ? <div className="menu">
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/') }} className={index === 'index' ? "menuItemActive" : "menuItem"}>首页</div>
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/enterTainment') }} className={index === 'enterTainment' ? "menuItemActive" : "menuItem"}>娱乐出行</div>
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/platForm') }} className={index === 'platForm' ? "menuItemActive" : "menuItem"}>车机平台</div>
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/aboutUs') }} className={index === 'aboutUs' ? "menuItemActive" : "menuItem"}>关于我们</div>
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/contact') }} className={index === 'contact' ? "menuItemActive" : "menuItem"}>联系我们</div>
        </div> : null
      }

      <img alt="" src={index==='platForm'?More1:More} className='more' onClick={() => { setShow(!show) }} />
    </div>
  </div>
}

export default Header